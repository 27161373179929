/* Screen Split */

.screen {
    position: relative;
    width: 100%;
    /*padding-top: 56.25%; /* 16:9 Aspect Ratio */
    border: 3px solid gray;
}

.center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
}

.not-selected {
    border: 2px solid black;
}

.selected {
    border: 5px solid green;
    color: green;
    font-weight: 600;
    background-color: lightgreen;
}

.screen169 {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
    border: 3px solid black;
}

.screen43 {
    position: relative;
    width: 100%;
    padding-top: 75%;
    /* 4:3 Aspect Ratio */
    border: 3px solid black;
}

.screen11 {
    position: relative;
    width: 100%;
    padding-top: 100%;
    /* 1:1 Aspect Ratio */
    border: 3px solid black;
}

.splitter-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: lightgray;
}

.splitter-content {
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: white;
    opacity: .75;
}

.splitter-selected {
    background-color: aqua;
}


/*

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}
*/